import "./CandidaturesInitialisationPageForm.scss"

import {useEffect, useState} from "react"

import {api} from "../../services"
import {getAnneeLabel, scrollToFirstError} from "../../helpers"
import {createSessionOptions, getSpecialisationConfig, getSpecialisationOptions} from "./helpers"
import FormFieldset from "../../../Form/Fieldset/FormFieldset"
import FormInputText from "../../../Form/FormInputText"
import FormSelect from "../../../Form/FormSelect"
import FormInputCheckbox from "../../../Form/InputCheckbox/FormInputCheckbox"
import FormInputEmail from "../../../Form/FormInputEmail"
import FormInputTel from "../../../Form/InputTel/FormInputTel"
import IconClose from "../../../Icon/IconClose"

export default function CandidaturesInitialisationPageForm({onButtonCloseClick}) {
  const [Annee, setAnnee] = useState("")
  const [candidatureExistante, setCandidatureExistante] = useState(false)
  const [Email, setEmail] = useState("")
  const [Nom, setNom] = useState("")
  const [Portable, setPortable] = useState("")
  const [Prenoms, setPrenoms] = useState("")
  const [Session, setSession] = useState("")
  const [Source, setSource] = useState("")
  const [Specialisation, setSpecialisation] = useState("")
  const [specialisationConfig, setSpecialisationConfig] = useState(null)
  const [anneeOptions, setAnneeOptions] = useState({})
  const [sessionOptions, setSessionOptions] = useState({})

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setSource(urlParams.get("src"))
  }, [])

  useEffect(() => {
    if (Specialisation === "") {
      return
    }

    setSpecialisationConfig(getSpecialisationConfig(Specialisation))
  }, [Specialisation])


  useEffect(() => {
    setAnnee("")

    const options = {}
    for (let annee in specialisationConfig?.annees) {
      options[annee] = getAnneeLabel(annee)
    }
    setAnneeOptions(options)
  }, [specialisationConfig])

  useEffect(() => {
    if (Annee === "") {
      return
    }

    setSession("")
    setSessionOptions(
      createSessionOptions(
        specialisationConfig?.annees[Annee].sessions
      )
    )
  }, [Annee])

  useEffect(() => {
    if (Object.keys(sessionOptions).length === 1) {
      setSession(Object.keys(sessionOptions)[0])
    }
  }, [sessionOptions])

  const onSubmit = (event) => {
    event.preventDefault()

    if (!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    setCandidatureExistante(false)

    api("POST", "/candidatures", {
      Annee,
      Email,
      Nom,
      Prenoms,
      Session,
      Specialisation,
      Portable,
      Source,
    })
      .then(({ID}) => {
        window.location = `/candidatures/${ID}`
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          setCandidatureExistante(true)
        } else {
          alert("Un problème est survenu. Veuillez réessayer.")
        }
      })
  }

  return (
    <section className={"CandidaturesInitialisationPageForm"}>
      <header className={"CandidaturesInitialisationPageForm-head"}>
        <div className={"CandidaturesInitialisationPageForm-headRowTitre"}>
          <h2 className={"CandidaturesInitialisationPageForm-titre tag-h1"}>
            Initialiser ma candidature
          </h2>
          <button
            aria-label={"Fermer le formulaire"}
            className={"CandidaturesInitialisationPageForm-closeFormOnMobile"}
            onClick={() => onButtonCloseClick()}
            title={"Fermer le formulaire"}
          >
            <IconClose/>
          </button>
        </div>
        <p className={"CandidaturesInitialisationPageForm-sousTitre tag-p is-m"}>
          La création de compte vous permettra de réaliser votre candidature en plusieurs fois si nécessaire.
        </p>
      </header>
      <form className={"CandidaturesInitialisationPageForm-form"} onSubmit={onSubmit} noValidate>
        <article className={"CandidaturesInitialisationPageForm-formBody helpers-formBody"}>
          <FormFieldset label={"Formation"}>
            <div className={"CandidaturesInitialisationPageForm-fieldsetFormation"}>
              <div className={"CandidaturesInitialisationPageForm-inputSpecialisation"}>
                <FormSelect
                  label={"Spécialisation"}
                  name={"Specialisation"}
                  onChange={(e) => setSpecialisation(e.target.value)}
                  options={getSpecialisationOptions()}
                  placeholder={"Sélectionner une spécialisation"}
                  required={true}
                  value={Specialisation}
                />
              </div>
              <div className={"CandidaturesInitialisationPageForm-inputAnnee"}>
                <FormSelect
                  disabled={Specialisation === ""}
                  label={"Année"}
                  name={"Annee"}
                  onChange={(e) => setAnnee(parseInt(e.target.value))}
                  options={anneeOptions}
                  placeholder={"Sélectionner une année"}
                  required={true}
                  value={Annee}
                />
              </div>
            </div>
            <FormSelect
              disabled={Annee === ""}
              label={"Date d’entrée dans l’école"}
              name={"Session"}
              onChange={(e) => setSession(e.target.value)}
              options={sessionOptions}
              placeholder={"Sélectionner une date d'entrée à l'école"}
              required={true}
              value={Session}
            />
          </FormFieldset>
          <div className="helpers-dualInputs">
            <FormInputText
              label={"Prénom"}
              name={"Prenoms"}
              onChange={(e) => setPrenoms(e.target.value)}
              placeholder={"ex: John"}
              required={true}
              value={Prenoms}
            />
            <FormInputText
              label={"Nom"}
              name={"Nom"}
              onChange={(e) => setNom(e.target.value)}
              placeholder={"ex: Doe"}
              required={true}
              value={Nom}
            />
          </div>
          <div className="helpers-dualInputs">
            <FormInputEmail
              label={"Email"}
              name={"Email"}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
              value={Email}
            />
            <FormInputTel
              label={"Portable"}
              name={"Portable"}
              onChange={(value) => setPortable(value)}
              required={true}
              value={Portable}
            />
          </div>
          <FormInputCheckbox
            required={true}
            label={
              <span>
                Dans le cadre de la réglementation sur la protection des données, j'accepte d'être contacté(e) par
                téléphone et tout autre moyen électronique. Pour plus en savoir plus,&nbsp;
                <a href={"https://www.hetic.net/donnees-personnelles"} className={"tag-a"} target={"_blank"}>
                  cliquez ici
                </a>.
              </span>
            }
          />
          {candidatureExistante && (
            <p className={"helpers-badge is-Stopper tag-p is-m"}>
              Nous avons constaté que la candidature que vous essayez de créer existe déjà dans notre système.
              Veuillez consulter vos SMS pour retrouver le lien qui vous permettra de la poursuivre.
            </p>
          )}
        </article>
        <footer className={"helpers-formFooter"}>
          <button
            aria-label={"Créer mon espace candidat"}
            className={"CandidaturesInitialisationPageForm-buttonSubmit tag-button"}
            title={"Créer mon espace candidat"}
            type={"submit"}
          >
            Créer mon espace candidat
          </button>
        </footer>
      </form>
    </section>
  )
}