import {Months, Specialisation} from "../../constants"
import {getSpecialisationLabel} from "../../helpers"

export const getSpecialisationConfig = (id) => {
  switch (id) {
    case Specialisation.BachelorDataEtIA:
      return {
        annees: {
          1: {
            alternance: false,
            sessions: [
              // {month: 10, year: 2024},
              {month: 1, year: 2025},
              {month: 2, year: 2025},
              {month: 10, year: 2025},
            ],
          },
          2: {
            alternance: true,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          3: {
            alternance: true,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.BachelorDeveloppementWeb:
      return {
        annees: {
          1: {
            alternance: false,
            sessions: [
              // {month: 10, year: 2024},
              {month: 1, year: 2025},
              {month: 2, year: 2025},
            ],
          },
          2: {
            alternance: false,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          3: {
            alternance: false,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.BachelorWebmarketingEtUX:
      return {
        annees: {
          1: {
            alternance: false,
            sessions: [
              // {month: 10, year: 2024},
              {month: 1, year: 2025},
              {month: 2, year: 2025},
              {month: 10, year: 2025},
            ],
          },
          2: {
            alternance: true,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          3: {
            alternance: true,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.BachelorUIProductDesigner:
      return {
        annees: {
          1: {
            alternance: false,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
          2: {
            alternance: true,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
          3: {
            alternance: true,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.BachelorCybersecurite:
      return {
        annees: {
          1: {
            alternance: false,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
          2: {
            alternance: true,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
          3: {
            alternance: true,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.PrepaMastereDigital:
      return {
        annees: {
          1: {
            alternance: false,
            sessions: [
              // {month: 10, year: 2024},
              {month: 1, year: 2025},
              {month: 2, year: 2025},
            ],
          },
        },
      }
    case Specialisation.PrepaMastereMarketingDesign:
      return {
        annees: {
          1: {
            alternance: false,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.PrepaMastereCybersecurite:
      return {
        annees: {
          1: {
            alternance: false,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.PrepaMastereTechData:
      return {
        annees: {
          1: {
            alternance: false,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.ProgrammeGrandeEcole:
      return {
        annees: {
          1: {
            alternance: false,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
              {month: 2, year: 2025},
            ],
          },
          2: {
            alternance: false,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          3: {
            alternance: false,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          4: {
            alternance: false,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          5: {
            alternance: true,
            sessions: [
              // {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.MastereCTOEtTechLead:
      return {
        annees: {
          4: {
            alternance: true,
            sessions: [
              {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          5: {
            alternance: true,
            sessions: [
              {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.MastereMarketingDigitalEtUX:
      return {
        annees: {
          4: {
            alternance: true,
            sessions: [
              {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          5: {
            alternance: true,
            sessions: [
              {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.MastereDataEtIA:
      return {
        annees: {
          4: {
            alternance: true,
            sessions: [
              {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          5: {
            alternance: true,
            sessions: [
              {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.MastereProductManager:
      return {
        annees: {
          4: {
            alternance: true,
            sessions: [
              {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          5: {
            alternance: true,
            sessions: [
              {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.MastereCybersecurite:
      return {
        annees: {
          4: {
            alternance: true,
            sessions: [
              {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
          5: {
            alternance: true,
            sessions: [
              {month: 10, year: 2024},
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.MastereDataPrivacyOfficer:
      return {
        annees: {
          4: {
            alternance: true,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
          5: {
            alternance: true,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.MastereProductDesignerExpert:
      return {
        annees: {
          4: {
            alternance: true,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
          5: {
            alternance: true,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
        },
      }
    case Specialisation.ExecutiveMBATechManagement:
    case Specialisation.ExecutiveMBAEcommerce:
    case Specialisation.ExecutiveMBADataEtIA:
    case Specialisation.ExecutiveMBADigitalMarketing:
      return {
        annees: {
          1: {
            alternance: true,
            sessions: [
              {month: 10, year: 2025},
            ],
          },
        },
      }
  }

  throw new Error(`'${id}' is not a valid Specialisation id`)
}

export const getSpecialisationOptions = () => {
  let options = {}

  for (let id in Specialisation) {
    options[id] = getSpecialisationLabel(id)
  }

  return options
}

export const createSessionOptions = (sessions) => {
  let options = {}

  sessions.forEach((session) => {
    const {month, year} = session
    const sessionMonthFrenchFormat = month.toString().padStart(2, "0")
    const optionKey = `${sessionMonthFrenchFormat}/${year}`
    options[optionKey] = `${Months[month - 1]} ${year}`
  })

  const sortedOptions = Object.entries(options).sort((a, b) => {
    let dateA = new Date(`01/${a[0]}`)
    let dateB = new Date(`01/${b[0]}`)
    return dateA.getTime() - dateB.getTime()
  })

  return Object.fromEntries(sortedOptions)
}