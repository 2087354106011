import "./CandidaturesInitialisationPage.scss"

import {useState} from "react"

import CandidaturesInitialisationPageForm from "./Form/CandidaturesInitialisationPageForm"
import CandidaturesInitialisationPageSidebar from "./Sidebar/CandidaturesInitialisationPageSidebar"

export default function CandidaturesInitialisationPage() {
  const [showForm, setShowForm] = useState(false)

  return (
    <div className="CandidaturesInitialisationPage">
      <aside className="CandidaturesInitialisationPage-sidebar">
        <CandidaturesInitialisationPageSidebar onButtonStartClick={() => setShowForm(true)}/>
      </aside>
      <main className={`CandidaturesInitialisationPage-form ${showForm ? "is-open" : ""}`}>
        <CandidaturesInitialisationPageForm onButtonCloseClick={() => setShowForm(false)}/>
      </main>
    </div>
  )
}